import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { mainStore, marketingDigitalStore } from '@/store/';
import { differenceInDays, parseISO } from 'date-fns';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
let TableHistoric = class TableHistoric extends Vue {
    constructor() {
        super(...arguments);
        this.historicosData = [];
        this.responsavelSelected = null;
        this.vigenciaAtiva = true;
        this.isLoading = false;
    }
    get headers() {
        const headers = [
            { text: 'Início Vigência', value: 'data_inicial_atividade' },
            { text: 'Fim Vigência', value: 'data_final_atividade' },
            { text: 'Valor da Agência', value: 'agencia_valor' },
            { text: 'Valor dos Créditos', value: 'valor_creditos' },
            { text: 'Qtd. de cupons', value: 'cupom_quantidade' },
            { text: 'Custo por lead', value: 'custo_lead' },
            { text: 'Qtd. de matrícula', value: 'matricula_quantidade' },
            { text: 'Custo por matrícula', value: 'matricula_custo' },
            { text: 'Valor do contrato', value: 'valor_contrato' },
            { text: 'Retorno', value: 'retorno' },
        ];
        if (this.currentUser.is_superuser) {
            headers.push({ text: 'Responsável', value: 'user_full_name' });
            headers.unshift({ text: 'Unidade', value: 'company_nome_fantasia' });
        }
        return headers;
    }
    get historicos() {
        return this.historicosData.map((historicoItem) => {
            const agenciaValor = historicoItem.agencia_valor;
            const valorCreditos = historicoItem.fundo_valor;
            const cupomQuantidade = historicoItem.cupom_quantidade;
            const diaAtual = new Date();
            const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getUTCMonth() + 1, 0).getDate();
            const dataHistorico = new Date(`${historicoItem.data_inicial_atividade} 23:59:59`);
            const mesAtividade = dataHistorico.getUTCMonth();
            const mesAtual = new Date().getMonth();
            let custoLead = null;
            if (cupomQuantidade) {
                if (mesAtividade === mesAtual) {
                    const dataInicialAtividade = parseISO(historicoItem.data_inicial_atividade);
                    custoLead = (((agenciaValor + valorCreditos) / lastDayOfMonth) * (differenceInDays(diaAtual, dataInicialAtividade))) / cupomQuantidade;
                }
                else {
                    custoLead = (((agenciaValor + valorCreditos) / lastDayOfMonth) * lastDayOfMonth) / cupomQuantidade;
                }
            }
            const matriculaQuantidade = historicoItem.matricula_quantidade;
            let matriculaCusto = null;
            if (matriculaQuantidade) {
                matriculaCusto = (agenciaValor + valorCreditos) / matriculaQuantidade;
            }
            return {
                company_nome_fantasia: historicoItem.company_nome_fantasia,
                user_full_name: historicoItem.user_full_name,
                data_inicial_atividade: historicoItem.data_inicial_atividade,
                data_final_atividade: historicoItem.data_final_atividade,
                agencia_valor: agenciaValor,
                valor_creditos: valorCreditos,
                cupom_quantidade: cupomQuantidade,
                custo_lead: custoLead,
                matricula_quantidade: matriculaQuantidade,
                matricula_custo: matriculaCusto,
                valor_contrato: historicoItem.valor_contrato,
                retorno: historicoItem.retorno,
                agencia_financeiro_parcela_status_id: historicoItem.agencia_financeiro_parcela_status_id,
                fundo_financeiro_parcela_status_id: historicoItem.fundo_financeiro_parcela_status_id,
            };
        });
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    async buscar() {
        this.isLoading = true;
        let responsavelId = null;
        if (this.responsavelSelected)
            responsavelId = this.responsavelSelected.id;
        this.historicosData = await marketingDigitalStore.marketingDigitalHistoricoByAdmin({ responsavelId, vigenciaAtiva: this.vigenciaAtiva });
        this.isLoading = false;
    }
    async mounted() {
        if (!this.currentUser.is_superuser) {
            this.historicosData = await marketingDigitalStore.marketingDigitalHistorico();
        }
    }
};
TableHistoric = __decorate([
    Component({
        components: {
            SearchEntidadeComponent
        }
    })
], TableHistoric);
export default TableHistoric;
