var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 card-digital",class:_vm.isMobile ? 'mt-4' : 'ma-4'},[_c('v-card-title',{staticClass:"fira-semi-bold title-historic"},[_vm._v("Histórico")]),_c('v-card-text',{class:_vm.isMobile ? 'pa-2' : ''},[(_vm.currentUser.is_superuser)?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"label":"Com Vigência ativa","hide-details":"auto"},model:{value:(_vm.vigenciaAtiva),callback:function ($$v) {_vm.vigenciaAtiva=$$v},expression:"vigenciaAtiva"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('SearchEntidadeComponent',{attrs:{"propTitle":"Usuário responsável","propEntityType":['usuario'],"propDisableValidator":true,"departamento_id":5},model:{value:(_vm.responsavelSelected),callback:function ($$v) {_vm.responsavelSelected=$$v},expression:"responsavelSelected"}})],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading,"rounded":""},on:{"click":_vm.buscar}},[_vm._v("Buscar")])],1):_vm._e(),_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-medium",attrs:{"headers":_vm.headers,"items":_vm.historicos,"dense":"","fixed-header":"","height":_vm.isMobile ? 'calc(100vh - 100px)' : 'calc(100vh - 510px)',"loading-text":"Carregando...","footer-props":{
          itemsPerPageOptions: [15, 25, 50, 100, 200, -1],
          itemsPerPageText: 'Itens por página',
        },"multi-sort":""},scopedSlots:_vm._u([{key:"item.data_inicial_atividade",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_inicial_atividade ? _vm.formatDate(item.data_inicial_atividade) : '-')+" ")]}},{key:"item.data_final_atividade",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_final_atividade ? _vm.formatDate(item.data_final_atividade) : '-')+" ")]}},{key:"header.matricula_quantidade",fn:function(ref){
        var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("A quantidade de matrículas é relacionado a quantos contratos foram criados")])])]}},{key:"item.mes_atividade",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.mes_atividade)?_c('span',{staticClass:"pa-2 border-purple",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.mes_atividade)+" ")]):_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.mes_atividade))])])]}},{key:"item.agencia_valor",fn:function(ref){
        var item = ref.item;
return [(!item.agencia_valor)?_c('div',[_c('span',{staticClass:"pa-2 border-red",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Não contratado ")])]):_c('div',[_c('span',[_vm._v("R$"+_vm._s(_vm.formatPriceValue(item.agencia_valor)))]),(item.agencia_financeiro_parcela_status_id === 1)?_c('span',{staticClass:"pa-2 border-orange",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Não Pago ")]):_vm._e(),(item.agencia_financeiro_parcela_status_id === 2)?_c('span',{staticClass:"pa-2 border-green",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Pago ")]):_vm._e()])]}},{key:"item.valor_creditos",fn:function(ref){
        var item = ref.item;
return [(!item.valor_creditos)?_c('div',[_c('span',{staticClass:"pa-2 border-red",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Não contratado ")])]):_c('div',[_c('span',[_vm._v("R$"+_vm._s(_vm.formatPriceValue(item.valor_creditos)))]),(item.fundo_financeiro_parcela_status_id === 1)?_c('span',{staticClass:"pa-2 border-orange",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Não Pago ")]):_vm._e(),(item.fundo_financeiro_parcela_status_id === 2)?_c('span',{staticClass:"pa-2 border-green",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Pago ")]):_vm._e()])]}},{key:"item.cupom_quantidade",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cupom_quantidade ? item.cupom_quantidade : '-')+" ")]}},{key:"item.custo_lead",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.custo_lead ? 'R$ ' + _vm.formatPriceValue(item.custo_lead) : '-')+" ")]}},{key:"item.matricula_quantidade",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.matricula_quantidade ? item.matricula_quantidade : '-')+" ")]}},{key:"item.matricula_custo",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.matricula_custo ? 'R$' + _vm.formatPriceValue(item.matricula_custo) : '-')+" ")]}},{key:"item.valor_contrato",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor_contrato ? 'R$' + _vm.formatPriceValue(item.valor_contrato) : '-')+" ")]}},{key:"item.retorno",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.retorno ? item.retorno + 'X' : '-')+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }